import { AuthService } from 'addiction-auth';

export const authServiceInit = (authService: AuthService) => {
  // init operazioni preliminari
  return async () => {
    if (!location.href.includes('/registration')) {
      try {
        await authService.runInitialLoginSequence();
        return;
      } catch (e) {
        console.error(e);
      }
    }
    return;
  };
};
