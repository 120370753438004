import { Routes } from '@angular/router';
import { ROUTES_NAME } from './shared/models/routes-name';
import { patientRoutes } from './pages/patient/patient-routes';
import { practiceRoutes } from './pages/practice/practice-routes';
import { practiceTemplateRoutes } from './pages/practice-template/practice-template-routes';
import { LoginGuard } from './core/auth/login.guard';
import { PracticePdfLayoutComponent } from './pages/print-layout/practice-pdf-layout/practice-pdf-layout.component';

export const appRoutes: Routes = [
  {
    path: ROUTES_NAME.HOME,
    canActivate: [LoginGuard],
    loadComponent: () => import('./pages/home/home.component').then((x) => x.HomeComponent),
    children: [
      {
        path: ROUTES_NAME.PATIENT,
        loadChildren: () => patientRoutes,
      },
      {
        path: ROUTES_NAME.PRACTICE,
        loadChildren: () => practiceRoutes,
      },
      {
        path: ROUTES_NAME.PRACTICE_TEMPLATE,
        loadChildren: () => practiceTemplateRoutes,
      },
      {
        path: ROUTES_NAME.PROFILE,
        loadComponent: () => import('./pages/profile/profile.component').then((x) => x.ProfileComponent),
      },
    ]
  },
  {
    path: ROUTES_NAME.LOGIN,
    loadComponent: () => import('./pages/login/login.component').then((x) => x.LoginComponent),
  },
  /**
   * Rotta gestita tramite outlet in modo da poter gestire una navigazione interna
   * senza effettivamente cambiare pagina.
   * I componenti children saranno visibili solo in pagina grazie al css
   */
  { path: ROUTES_NAME.PRINT,
    outlet: 'print',
    loadComponent: () => import('./pages/print-layout/print-layout.component').then((x) => x.PrintLayoutComponent),
    children: [
      { path: `${ROUTES_NAME.PRACTICE}/:practiceUUID`, component: PracticePdfLayoutComponent }
    ]
  }
];
