import { Routes } from '@angular/router';

export const patientRoutes: Routes = [
  {
    path: '',
    loadComponent: () => import('./patient-list/patient-list.component').then((x) => x.PatientListComponent),
  },
  {
    path: 'new',
    loadComponent: () => import('./patient-editor/patient-editor.component').then((x) => x.PatientEditorComponent),
  },
  {
    path: 'edit/:patientUUID',
    loadComponent: () => import('./patient-editor/patient-editor.component').then((x) => x.PatientEditorComponent),
  },
];
