import { Routes } from '@angular/router';
import { ROUTES_NAME } from './shared/models/routes-name';
import { patientRoutes } from './pages/patient/patient-routes';
import { practiceRoutes } from './pages/practice/practice-routes';
import { practiceTemplateRoutes } from './pages/practice-template/practice-template-routes';
import { LoginGuard } from './core/auth/login.guard';

export const appRoutes: Routes = [
  {
    path: ROUTES_NAME.HOME,
    canActivate: [LoginGuard],
    loadComponent: () => import('./pages/home/home.component').then((x) => x.HomeComponent),
    children: [
      {
        path: ROUTES_NAME.PATIENT,
        loadChildren: () => patientRoutes,
      },
      {
        path: ROUTES_NAME.PRACTICE,
        loadChildren: () => practiceRoutes,
      },
      {
        path: ROUTES_NAME.PRACTICE_TEMPLATE,
        loadChildren: () => practiceTemplateRoutes,
      },
      {
        path: ROUTES_NAME.PROFILE,
        loadComponent: () => import('./pages/profile/profile.component').then((x) => x.ProfileComponent),
      },
    ]
  },
  {
    path: ROUTES_NAME.LOGIN,
    loadComponent: () => import('./pages/login/login.component').then((x) => x.LoginComponent),
  },
];
