import { inject } from '@angular/core';
import { HttpInterceptorFn } from '@angular/common/http';
import { throwError, catchError } from 'rxjs';
import { AuthService } from 'addiction-auth';

export const ErrorInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);
  return next(req).pipe(
    catchError((err) => {
      if (err.status === 401) {
        // auto logout if 401 response returned from api
          authService.logout();
      }
      return throwError(() => err);
    })
  );
}
