

<div class="print-content">
  @if (data$ | async; as data) {
    @for (section of data.practice.sections; track $index) { 
      @if (isSectionRefValueQuestionAndAnswerValue(section.value)) {
      <div class="question" [innerHTML]="section.value.question | placeholderResolver : data.placeholder"></div>
      <div class="answer" [innerHTML]="section.value.answer | placeholderResolver : data.placeholder"></div>
    } @else {
    <div [innerHTML]="section.value | placeholderResolver : data.placeholder"></div>
    } 
  }
}
</div>

