import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { map, skipWhile } from 'rxjs/operators';
import { AuthService } from 'addiction-auth';
import { ROUTES_NAME } from '../../shared/models/routes-name';

@Injectable({
    providedIn: 'root',
})
export class LoginGuard {
    constructor(private router: Router, private authService: AuthService) {}

    canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
        return this.authService.isDoneLoading$.pipe(
            skipWhile((x) => !x),
            map(() => {
                if (!this.authService.hasValidToken()) {
                    this.router.navigate([ROUTES_NAME.LOGIN]);
                    return false;
                } else {
                    return true;
                }
            }),
        );
    }
}
