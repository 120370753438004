import { Routes } from '@angular/router';

export const practiceTemplateRoutes: Routes = [
  {
    path: '',
    loadComponent: () => import('./practice-template-list/practice-template-list.component').then((x) => x.PracticeTemplateListComponent),
  },
  {
    path: 'new',
    loadComponent: () => import('./practice-template-editor/practice-template-editor.component').then((x) => x.PracticeTemplateEditorComponent),
  },
  {
    path: 'edit/:practiceTemplateUUID',
    loadComponent: () => import('./practice-template-editor/practice-template-editor.component').then((x) => x.PracticeTemplateEditorComponent),
  },

  {
    path: 'clone/:practiceTemplateUUID',
    loadComponent: () => import('./practice-template-editor/practice-template-editor.component').then((x) => x.PracticeTemplateEditorComponent),
  },
];
