import { ModuleWithProviders, NgModule } from '@angular/core';
import { EnvironmentConfiguration } from 'addiction-components';
import { AuthConfig, OAuthModule, OAuthModuleConfig, OAuthStorage } from 'angular-oauth2-oidc';

export function storageFactory(): OAuthStorage {
  return localStorage;
}

@NgModule({
  declarations: [],
  imports: [OAuthModule.forRoot()],
  providers: [
    {provide: OAuthStorage, useFactory: storageFactory}
  ],
  exports: [],
})
export class AddictionAuthModule {
  static forRoot(
    env: EnvironmentConfiguration,
    authConfig: AuthConfig,
    authModuleConfig: OAuthModuleConfig
  ): ModuleWithProviders<AddictionAuthModule> {
    return {
      ngModule: AddictionAuthModule,
      providers: [
        { provide: 'env', useValue: env },
        { provide: AuthConfig, useValue: authConfig },
        { provide: OAuthModuleConfig, useValue: authModuleConfig },
      ],
    };
  }
}
