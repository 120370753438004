import { environment } from './../../../environments/environment';
import { AuthConfig, OAuthModuleConfig } from "angular-oauth2-oidc";

export const authConfig: AuthConfig = {
  oidc: true,
  issuer: environment.authenticationUrl,
  customQueryParams: {
    organizationUUID: environment.organizationUUID,
    userType: 'ApplicationUser@cataleanUser', //Tipo_UTENTE@Tipo_INTEGRAZIONE
    prompt: 'login',
    registrationUrl: environment.registration.enabled ? environment.registration.url : ''
  },
  requireHttps: environment.production,
  clientId: 'consenso-informato-integration', // The "Auth Code + PKCE" client
  responseType: 'code',
  redirectUri: window.location.origin,
  logoutUrl: window.location.origin,
  scope: 'openid profile email offline_access', // Ask offline_access to support refresh token refreshes
  timeoutFactor: 0.75, // decrease this value for faster testing
  sessionChecksEnabled: false,
  showDebugInformation: !environment.production,
  clearHashAfterLogin: false, // https://github.com/manfredsteyer/angular-oauth2-oidc/issues/457#issuecomment-431807040,
  nonceStateSeparator: 'semicolon', // Real semicolon gets mangled by IdentityServer's URI encoding
  tokenEndpoint: environment.authenticationUrl + 'oidc/token',
};

export const authModuleConfig: OAuthModuleConfig = {
  resourceServer: {
    allowedUrls: [],
    sendAccessToken: true,
  },
};
