import { AfterViewInit, ChangeDetectorRef, Component, inject } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { Practice } from '../../../shared/models/practice';
import { ActivatedRoute } from '@angular/router';
import {
  combineLatest,
  filter,
  map,
  Observable,
  shareReplay,
  switchMap,
  take,
  throwError,
} from 'rxjs';
import { PrintService } from '../../../shared/services/print.service';
import { PracticeService } from '../../../shared/services/practice.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PLACEHOLDER_SECTION, PlaceholderSectionElementMap } from '../../../shared/models/editor-with-section/placeholder-section';
import { SectionPlaceholderResolver } from '../../../shared/pipes/section-placeholder-resolver';

@Component({
  selector: 'app-practice-pdf-layout',
  standalone: true,
  imports: [CommonModule, SectionPlaceholderResolver],
  templateUrl: './practice-pdf-layout.component.html',
  styleUrl: './practice-pdf-layout.component.scss',
  providers: [DatePipe]
})
export class PracticePdfLayoutComponent implements AfterViewInit {
  private activatedRoute = inject(ActivatedRoute);
  private printService = inject(PrintService);
  private practiceService = inject(PracticeService);
  private datePipe = inject(DatePipe);
  private cd = inject(ChangeDetectorRef);

  practice$: Observable<Practice> = this.activatedRoute.params.pipe(
    switchMap((params) => {
      const practiceUUID = params['practiceUUID'];
      if (practiceUUID) {
        return this.practiceService.fetchPracticeByUUID(practiceUUID);
      }
      throw throwError(() => new Error('Router state did not return a state'));
    }),
    shareReplay({ refCount: true, bufferSize: 1 })
  );

  placeholdersMap$: Observable<PlaceholderSectionElementMap[]> = this.practice$.pipe(
    takeUntilDestroyed(),
    map((practice) => {
      return [
        { placeholder: PLACEHOLDER_SECTION.PATIENT_NAME, value: practice.patientInfo.fullName },
        { placeholder: PLACEHOLDER_SECTION.PRACTICE_DATE, value: this.datePipe.transform(practice.practiceDate) ?? '' },
      ];
    }),
    shareReplay({ refCount: true, bufferSize: 1 })
  );

  data$ = combineLatest([this.practice$, this.placeholdersMap$]).pipe(
    map(([practice, placeholder]) => ({practice, placeholder})),
    shareReplay({ refCount: true, bufferSize: 1 })
  );

  isSectionRefValueQuestionAndAnswerValue(sectionRefValue: unknown): sectionRefValue is { question: string; answer: string } {
    return !!sectionRefValue && typeof sectionRefValue === 'object' && 'question' in sectionRefValue;
  }

  ngAfterViewInit(): void {
    this.data$
      .pipe(
        filter((practice) => !!practice),
        take(1),
      )
      .subscribe({
        next: () => {
          // forzo angular a controllare di nuovo la view altrimenti vedo pagina vuota
          this.cd.detectChanges();
          this.printService.onDataReady()
        },
      });
  }
}
