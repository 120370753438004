import { Component, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterModule } from '@angular/router';
import { AuthService } from 'addiction-auth';
import { filter, switchMap, tap } from 'rxjs';

@Component({
  standalone: true,
  imports: [RouterModule],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  constructor() {
    const authService = inject(AuthService);
    authService.isDoneLoading$
      .pipe(
        filter((x) => !!x),
        switchMap(() => authService.isAuthenticated$),
        takeUntilDestroyed()
      )
      .subscribe({
        next: (isAuthenticated) => {
          if (!isAuthenticated) {
            authService.login();
          }
        },
      });
  }
}
