import { environment } from './../../../environments/environment';
import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from 'addiction-auth';

export const AuthJWTInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);
  if (!req.url.includes(environment.authenticationUrl)) {
    req = req.clone({
      setHeaders: {
        Authorization: 'Bearer ' + authService.idToken,
      },
    });
  }

  return next(req);
};
